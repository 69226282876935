export const baseRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/login'),
    meta: {
      title: '登录',
      pageTag: false,
      pageHeader: false,
      auth: false,
    },
  },
  {
    path: '/regist',
    name: 'regist',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/regist'),
    meta: {
      title: '注册',
      pageTag: false,
      pageHeader: false,
      auth: false,
    },
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('@/views/admin/password'),
    meta: {
      title: '找回密码',
      pageTag: false,
      pageHeader: false,
      auth: false,
    },
  },
  {
    path: '/manager/office',
    name: 'manager/office',
    component: () => import('@/views/manager/officeOnline'),
    meta: {
      title: '精准监督-在线文档预览',
      pageTag: false,
      pageHeader: false,
      auth: true,
    },
  },
  {
    path: '/dp/index',
    name: 'dp/index',
    component: () => import('@/views/dp/index'),
    meta: {
      title: '大厅屏',
      pageTag: false,
      pageHeader: false,
      auth: false,
    },
  },
  {
    path: '/data/index',
    name: 'data/index',
    component: () => import('@/views/data/index'),
    meta: {
      title: '决策指挥',
      pageTag: false,
      pageHeader: false,
      auth: false,
    },
  },
  {
    path: '/test/test',
    name: 'test/test',
    component: () => import('@/views/test/test.vue'),
    meta: {
      title: '工作',
      pageTag: false,
      pageHeader: false,
      auth: false,
    },
  },
  {
    path: '/data/renderingEngine',
    name: 'data/renderingEngine',
    component: () => import('@/views/data/renderingEngine'),
    meta: {
      title: 'renderingEngine',
      pageTag: false,
      pageHeader: false,
      auth: false,
    },
  },
  {
    path: '/data/index2',
    name: 'data/index2',
    component: () => import('@/views/tool/build/index'),
  },
]

export const errorRoutes = [
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/error/404'),
    meta: {
      title: '404',
      auth: false,
    },
  },
]
